<template>
  <div id="login" v-cloak>
    <nav class="nav-box">
      <img src="@/assets/logo-black.png" alt="logo" class="logo" />
      <el-dropdown class="changeLang" trigger="click" @command="changeLangFn">
        <span class="el-dropdown-link">
          <i class="iconfont icon-qiehuan"></i>
          {{ $t("changeLanguage") }}
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="item in langArr"
            :command="item.id"
            :key="item.id"
          >
            {{ item.name }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </nav>
    <div class="container">
      <el-form :model="loginFrom" class="loginFrom">
        <el-form-item>
          <h1 class="site-name">{{ $t("homeManage") }}</h1>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model.trim="loginFrom.user"
            class="inputW"
            clearable
            :placeholder="$t('login.account')"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model.trim="loginFrom.psw"
            class="inputW"
            clearable
            type="password"
            :placeholder="$t('login.password')"
          ></el-input>
        </el-form-item>
        <el-form-item class="codeBox">
          <el-input
            v-model.trim="loginFrom.code"
            class="codeW"
            clearable
            :placeholder="$t('login.captcha')"
          ></el-input>
          <img :src="codeSrc" alt="code" class="codeImg" @click="changeCode" />
        </el-form-item>
        <el-form-item class="subBox">
          <el-button
            type="primary"
            @click="submit"
            @keyup.enter="submit"
            class="subBtn"
          >
            {{ $t("submit") }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <a href="http://www.beian.miit.gov.cn" target="_blank" class="record-box">
      {{ $t("login.record") }}
    </a>
  </div>
</template>

<script>
import { getCookie, setCookie } from "@/utils/cookie";

export default {
  name: "Login",
  data() {
    return {
      langArr: [
        {
          id: 0,
          value: "zh",
          name: "中文",
        },
        {
          id: 1,
          value: "en",
          name: "English",
        },
        {
          id: 2,
          value: "ru",
          name: "русский",
        },
      ],
      lang: 0,
      codeSrc: "/captcha",
      loginFrom: {
        user: "",
        psw: "",
        code: "",
      },
    };
  },
  methods: {
    submit: function () {
      if (this.loginFrom.user === "") {
        this.$message({
          message: this.$t("login.emptyName"),
          type: "error",
        });
        return;
      }
      if (this.loginFrom.psw === "") {
        this.$message({
          message: this.$t("login.emptyPassword"),
          type: "error",
        });
        return;
      }
      if (this.loginFrom.code === "") {
        this.$message({
          message: this.$t("login.emptyCode"),
          type: "error",
        });
        return;
      }
      this.axios
        .post("/home/login/login", {
          account: this.loginFrom.user,
          password: this.loginFrom.psw,
          code: this.loginFrom.code,
          lang: this.lang,
        })
        .then((res) => {
          if (res.data.status !== 1) {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
            this.changeCode();
            return;
          }
          setCookie("isChange", 0);
          setCookie("USERNAME", this.loginFrom.user);
          this.$router.push({ path: "/" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 切换语言
    changeLangFn: function (id) {
      setCookie("lang", id);
      this.lang = id;
      setCookie("isChange", 1);
      this.$i18n.locale = this.langArr[id].value;
    },
    // 更改验证码
    changeCode: function () {
      this.codeSrc = this.codeSrc + "&t=" + Math.random();
    },
  },
  created: function () {
    // 默认进来没有设置cookie,lang就设置为英文
    if (getCookie("lang") == null) {
      setCookie("lang", 0);
    } else {
      this.lang = getCookie("lang");
    }
    this.$i18n.locale = this.langArr[this.lang].value;
  },
};
</script>

<style scoped>
@import url("//at.alicdn.com/t/font_1249734_5vu79tw7nmr.css");

.container {
  width: 1200px;
  margin: 50px auto 0;
}
.logo {
  width: 200px;
  height: 50px;
  object-fit: contain;
}
.nav-box {
  display: flex;
  justify-content: space-between;
  padding: 50px;
  align-items: center;
}
#login {
  height: 100%;
  background-image: url("~@/assets/login_bg.svg");
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}
.loginFrom {
  width: 350px;
  margin: 0 auto;
  animation: formMove 1s 1;
}
.site-name {
  text-align: center;
  margin-top: 30px;
  font-weight: bold;
  font-size: 24px;
  position: relative;
  margin-bottom: 35px;
}
.site-name::after {
  content: "";
  position: absolute;
  bottom: -20px;
  background-image: linear-gradient(to right, #ffba42, #e373c5);
  width: 120px;
  height: 4px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
@keyframes formMove {
  from {
    transform: translateY(50px);
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
.el-form-item__label {
  color: #333;
}
.el-input__inner {
  background: transparent;
  border: 1px solid #626262;
}
.el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border: 1px solid #333;
}
.inputW {
  width: 100%;
}
.codeW {
  width: 50%;
}
.codeImg {
  width: 50%;
  margin-left: 20px;
  height: 40px;
}
.subBtn {
  width: 350px;
  background-image: linear-gradient(to bottom right, #ff6eaa 20%, #8989ff);
}
.subBtn:active {
  box-shadow: 0px 4px 8px rgba(darken(dodgerblue, 30%));
  transform: scale(0.98);
}
.subBox {
  display: flex;
  justify-content: flex-end;
}
.codeBox >>> .el-form-item__content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.changeLang {
  margin-right: 70px;
  color: #000;
  margin-top: 15px;
  cursor: pointer;
  font-size: 18px;
}
.changeLang i {
  color: #000;
}

.el-dropdown-menu {
  background-color: #8989ff;
  border: none;
  padding: 0;
  width: 140px;
  text-align: center;
}

.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #ff6eaa;
  color: #fff;
}

.el-dropdown-menu__item {
  background-color: #8989ff;
  color: #fff;
}
.el-popper .popper__arrow,
.el-popper .popper__arrow::after {
  border: none;
}
.el-button--primary {
  color: #fff;
  background-image: linear-gradient(to bottom right, #ff6eaa 20%, #8989ff);
  border: none;
}
.el-button--primary:focus,
.el-button--primary:hover {
  background-image: linear-gradient(to bottom right, #ff6eaa 20%, #8989ff);
  border-color: none;
  color: #fff;
  opacity: 0.9;
}
/* 升级浏览器样式 */
.header .logo img {
  width: 150px;
}
.footer,
.header {
  min-width: 1000px;
  margin: 0 auto;
}

.header {
  background-color: #ffffff;
}

.header .wrap.navWrap {
  max-width: 1000px;
  margin: 0 auto;
}

.aui_close,
.aui_close:hover,
.aui_dialog .layer .btn_c1:hover,
.aui_state_noTitle .aui_close,
.nav li a,
a {
  text-decoration: none;
}

.clearfix:after {
  content: ".";
  display: table;
  height: 0;
  font-size: 0;
  line-height: 0;
  clear: both;
  visibility: hidden;
}

.clearfix {
  zoom: 1;
}

a,
div,
img,
p,
span {
  -webkit-tap-highlight-color: transparent;
}

body {
  background: #f5f6f9;
  color: #333;
  font-size: 14px;
}

.header {
  background: #fff;
  height: 90px;
  padding: 0;
  font-size: 16px;
  color: #666;
  align-items: center;
}

.header .logo {
  float: left;
  margin-top: 20px;
  margin-bottom: 0;
}

.upgradeWap {
  width: 1000px;
  margin: 0 auto;
}

.browWarpBox {
  width: 508px;
  margin: 0px auto 230px;
  overflow: hidden;
  padding-top: 250px;
}

.tipMessage {
  height: 125px;
  font-size: 14px;
  border-bottom: 1px solid #ccc;
}

.tipMessage p {
  line-height: 30px;
  color: #333;
}

.tipMessage h2 {
  color: #18a2fb;
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 15px;
}

.browWrap {
  margin-top: 25px;
  width: 580px;
  margin-left: -25px;
}

.browWrap .browItem {
  float: left;
  width: 140px;
  background: url(https://qzonestyle.gtimg.cn/aoi/sola/20171027205700_l0LAh1WImq.jpg)
    no-repeat;
  height: 97px;
  position: relative;
  text-align: center;
}

.browWrap .browItem span {
  position: absolute;
  bottom: 0;
  display: block;
  height: 20px;
  color: #959595;
  width: 100%;
  left: 0;
}

.browWrap .browItem:hover span {
  color: #18a2fb;
}

.browWrap .browItem.firefox {
  background-position: 38px 0;
}

.browWrap .browItem.ie {
  background-position: -105px 0;
}

.browWrap .browItem.chrome {
  background-position: -248px 0;
}

.browWrap .browItem.qqbrowser {
  background-position: -388px 0;
}
.record-box {
  color: #666666;
  font-size: 12px;
  text-align: center;
  line-height: 30px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  text-decoration: none;
}
</style>